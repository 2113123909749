@font-face {
font-family: '__latoFont_ae554c';
src: url(/_next/static/media/155cae559bbd1a77-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__latoFont_ae554c';
src: url(/_next/static/media/92fc6f9601a72ee5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__latoFont_ae554c';
src: url(/_next/static/media/543bf3efe5899c64-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__latoFont_ae554c';
src: url(/_next/static/media/06edd0e0661b84d8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__latoFont_ae554c';
src: url(/_next/static/media/af842e488288596c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__latoFont_ae554c';
src: url(/_next/static/media/5005a828f92ffee3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__latoFont_ae554c';
src: url(/_next/static/media/ed67ffd5c2c9ccb8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__latoFont_Fallback_ae554c';src: local("Arial");ascent-override: 99.53%;descent-override: 21.48%;line-gap-override: 0.00%;size-adjust: 99.17%
}.__className_ae554c {font-family: '__latoFont_ae554c', '__latoFont_Fallback_ae554c'
}.__variable_ae554c {--font-lato: '__latoFont_ae554c', '__latoFont_Fallback_ae554c'
}

